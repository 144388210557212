import { Container, Typography, Link, Grid } from "@mui/material";
import SocialShare from "../Social/SocialShare";
import TrustBox from "../Dashboard/trustbox";
/**
 *
 * Contact
 */
export default function TrustBoxPage() {
  return (
    <Container sx={{ pt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography color="primary" component="h2" variant="h5" sx={{ marginBottom: 1 }}>
            Thank you for donating with us!
          </Typography>

          <Typography paragraph sx={{ typography: "body1" }}>
            We are always looking for feedback, and would love to hear your opinion.
          </Typography>

          <Typography paragraph sx={{ typography: "body1" }}>
            If you could take a moment to leave a review on Trustpilot, we would greatly appreciate it.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TrustBox />
        </Grid>
      </Grid>
    </Container>
  );
}
